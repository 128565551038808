<template>
  <q-table
    :columns="[
      {
        name: 'identifier',
        required: true,
        label: 'Číslo semináře',
        align: 'left',
        field: (row: Seminar) => row.identifier,
        sortable: true
      },
      {
        name: 'name',
        required: true,
        label: 'Název',
        align: 'left',
        field: (row: Seminar) => row.name,
        sortable: true
      },
      {
        name: 'date',
        required: true,
        label: 'Datum',
        align: 'left',
        field: (row: Seminar) => row.date,
        format: (val: Date) => showDate(val),
        sortable: true
      },
      {
        name: 'lector',
        required: true,
        label: 'Lektor',
        align: 'left',
        field: (row: Seminar) => row.lector,
        sortable: true
      },
      {
        name: 'participantsCount',
        required: true,
        label: 'Počet účastníků',
        align: 'left',
        field: (row: Seminar) => row.participants?.length,
        sortable: true,
        sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10)
      },
      {
        name: 'actions',
        label: 'Akce',
        field: () => null
      }
    ]"
    :filter="filter"
    :filter-method="searchFilter"
    :pagination="{
      rowsPerPage: 30,
      sortBy: 'date',
    }"
    :rows="seminars"
    no-data-label="Zatím jste nevytvořili žádný seminář"
    row-key="name"
    @row-click="onRowClick"
  >
    <template v-slot:top>
      <span class="text-h6">Semináře</span>

      <q-space />

      <q-checkbox
        :model-value="upcomingSeminarsCheckbox"
        class="q-mr-lg"
        label="Pouze nadcházející semináře"
        @update:model-value="$emit('toggle-upcoming-seminars-checkbox')"
      />

      <q-input
        v-model="search"
        class="q-mr-lg"
        color="primary"
        debounce="100"
        dense
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>

      <q-btn color="secondary" @click="$router.push('seminar/new')">
        Vytvořit nový seminář
      </q-btn>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <q-btn
          color="negative"
          dense
          flat
          icon-right="delete"
          no-caps
          @click.stop="$emit('delete-seminar', props.row)"
        />
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts" setup>
import type { Seminar } from "@/components/seminar/SeminarTable/types";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import {
  convertToQuasarDate,
  isValidDate,
} from "@/components/seminar/translators/date";
import { formatDateWithTime } from "date-formatter";

const router = useRouter();

defineProps<{
  seminars: Seminar[];
  upcomingSeminarsCheckbox: boolean;
}>();

defineEmits<{
  (e: "toggle-upcoming-seminars-checkbox", value: boolean): void;
  (e: "delete-seminar", id: Seminar): void;
}>();

const search = ref("");
const filter = computed(() => ({ search: search.value }));

const searchFilter = (seminars: readonly Seminar[]) => {
  const lowerSearch = search.value.toLowerCase();

  return seminars.filter((row) => {
    const { participants, __typename, ...filterableObject } = row;

    if (lowerSearch === "") {
      return true;
    }

    let filterableValues = Object.values(filterableObject);
    let filterableLowerValues = filterableValues.map((x) =>
      x?.toString().toLowerCase()
    );
    for (let index = 0; index < filterableLowerValues.length; index++) {
      let value = filterableLowerValues[index];
      if (!value) {
        return false;
      }

      const date = new Date(value);
      if (isValidDate(date)) {
        value = convertToQuasarDate(date);
      }

      if (value.includes(lowerSearch)) {
        return true;
      }
    }
    return false;
  });
};

const showDate = (date: Date) => {
  return formatDateWithTime(date);
};

const onRowClick = (ev: Event, row: Seminar) => {
  router.push(`/seminar/${row.id}`);
};
</script>
