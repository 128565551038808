var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// index.ts
var PaymentMethodTranslated = /* @__PURE__ */ ((PaymentMethodTranslated2) => {
  PaymentMethodTranslated2["Cash"] = "Hotov\u011B";
  PaymentMethodTranslated2["Free"] = "Zdarma";
  PaymentMethodTranslated2["Invoice"] = "Faktura";
  return PaymentMethodTranslated2;
})(PaymentMethodTranslated || {});
var translatedPaymentMethods = Object.values(PaymentMethodTranslated);
var translator = {
  ["CASH" /* Cash */]: "Hotov\u011B" /* Cash */,
  ["FREE" /* Free */]: "Zdarma" /* Free */,
  ["INVOICE" /* Invoice */]: "Faktura" /* Invoice */
};
var translatePaymentMethodToCzech = (paymentMethod) => {
  return translator[paymentMethod];
};
var translatePaymentMethodToEnum = (paymentMethodTranslated) => {
  const entries = Object.entries(translator);
  let toEnum = {};
  entries.forEach(([key, name]) => {
    toEnum = __spreadProps(__spreadValues({}, toEnum), {
      [name]: key
    });
  });
  if (!(paymentMethodTranslated in toEnum)) {
    throw new Error(`Unknown payment method: ${paymentMethodTranslated}`);
  }
  return toEnum[paymentMethodTranslated];
};
export {
  PaymentMethodTranslated,
  translatePaymentMethodToCzech,
  translatePaymentMethodToEnum,
  translatedPaymentMethods
};
