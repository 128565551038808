import { createRouter, createWebHistory } from "vue-router";
import SeminarsView from "@/views/seminars/SeminarsView.vue";
import SeminarView from "@/views/seminars/SeminarView.vue";
import SeminarCreate from "@/views/seminars/SeminarCreate.vue";
import SeminarUpdate from "@/views/seminars/SeminarUpdate.vue";
import ProfileView from "@/views/ProfileView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      // / -> /seminars
      path: "/",
      redirect: () => {
        return { path: "/seminars" };
      },
    },
    {
      path: "/seminars",
      component: SeminarsView,
    },
    {
      path: "/seminar/:id",
      component: SeminarView,
    },
    {
      path: "/seminar/new",
      component: SeminarCreate,
    },
    {
      path: "/seminar/edit/:id",
      component: SeminarUpdate,
    },
    {
      path: "/profile",
      component: ProfileView,
    },
  ],
});

export default router;
