import { z } from "zod";
import { toFormValidator } from "@vee-validate/zod";

const zodSchema = z.object({
  firstName: z.string().min(1, "Tato položka je povinná"),
  lastName: z.string().min(1, "Zvolte unikátní ID semináře"),
  email: z.string().email("Zadejte platný email"),
  degree: z.string(),
  address: z.string(),
  phone: z.string(),
});

export const profileValidationSchema = toFormValidator(zodSchema);
export type ProfileFormValues = z.infer<typeof zodSchema>;
