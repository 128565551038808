import { graphql } from "@/api/graphql/codegen";

export const AddParticipantsMutationDocument = graphql(/* GraphQL */ `
  mutation AddParticipantsMutation(
    $addParticipantsInput: AddParticipantsInput!
  ) {
    addParticipants(addParticipantsInput: $addParticipantsInput) {
      participants {
        name
      }
    }
  }
`);
