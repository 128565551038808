import { graphql } from "@/api/graphql/codegen";

export const UpdateUserIdentityMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateUserIdentity($userIdentity: UpdateIdentityInput!) {
    updateUserIdentity(identity: $userIdentity) {
      firstName
      lastName
      degree
      phone
      address
      email
    }
  }
`);
