import type { UseMutationOptions } from "@tanstack/vue-query";
import { useMutation } from "@tanstack/vue-query";
import type { Variables } from "graphql-request";
import request from "graphql-request";
import { useAuth0 } from "@auth0/auth0-vue";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";

export function useGraphQLMutation<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  options: Omit<
    UseMutationOptions<TResult, unknown, TVariables, unknown>,
    "mutationFn"
  > = {}
) {
  const auth0 = useAuth0();

  return useMutation({
    ...(options as unknown[]),
    mutationFn: async (variables: TVariables) => {
      const accessToken = await auth0.getAccessTokenSilently({
        audience: import.meta.env.VITE_AUTH_AUDIENCE,
      });

      return await request(
        import.meta.env.VITE_API_URL as string,
        document,
        variables as Variables,
        {
          authorization: `Bearer ${accessToken}`,
        }
      );
    },
  });
}
