import request from "graphql-request";
// import { useQuery, UseQueryOptions } from "@tanstack/vue-query";
import { useAuth0 } from "@auth0/auth0-vue";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { unref } from "vue";
import type { UseQueryOptions } from "@tanstack/vue-query";
import { useQuery } from "@tanstack/vue-query";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";

export function useGraphQLQuery<
  TResult,
  TVariables,
  TQueryKey extends unknown[]
>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables extends Record<string, never>
    ? never
    : MaybeRef<TVariables>,
  options: Omit<
    UseQueryOptions<TResult, unknown, TResult, TQueryKey>,
    "queryFn" | "queryKey"
  > = {}
) {
  const auth0 = useAuth0();

  const queryKey = [(document.definitions[0] as any).name.value, variables];

  const query = useQuery(queryKey, {
    ...(options as unknown[]),
    queryFn: async ({ queryKey }) => {
      const accessToken = await auth0.getAccessTokenSilently({
        audience: import.meta.env.VITE_AUTH_AUDIENCE,
      });

      return request(
        import.meta.env.VITE_API_URL as string,
        document,
        unref(queryKey[1]) ? unref(queryKey[1]) : undefined,
        {
          authorization: `Bearer ${accessToken}`,
        }
      );
    },
  });

  return {
    ...query,
    queryKey,
  };
}
