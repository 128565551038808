import type { ParticularSeminar } from "@/components/seminar/SeminarTable/types";
import * as XLSX from "xlsx";
import { translatePaymentMethodToCzech } from "payment-method-translator";
import type { ParticipantDto } from "@/api/graphql/codegen/graphql";
import { formatDate } from "date-formatter";

type ReportColumns = Pick<
  ParticipantDto,
  | "name"
  | "email"
  | "dateOfBirth"
  | "paymentMethod"
  | "phone"
  | "school"
  | "invoiceEmail"
  | "ico"
  | "note"
>;

export const useGenerateParticipantsXlsx = () => {
  const generateXlsx = async (seminar: ParticularSeminar) => {
    if (!seminar.participants) return;

    const header: Record<keyof ReportColumns, string> = {
      name: "Jméno",
      email: "E-mail",
      dateOfBirth: "Datum narození",
      phone: "Telefon",
      school: "Škola",
      ico: "IČO",
      invoiceEmail: "Fakturační email",
      paymentMethod: "Platební metoda",
      note: "Poznámka",
    };

    const participantRows: Array<Record<keyof ReportColumns, string>> =
      seminar.participants.reduce(
        (prev, curr) => [
          ...prev,
          // This has to be in same order as headerTranslate
          {
            name: curr.name,
            email: curr.email ?? "",
            dateOfBirth: formatDate(curr.dateOfBirth),
            phone: curr.phone ?? "",
            school: curr.school ?? "",
            ico: curr.ico ?? "",
            invoiceEmail: curr.invoiceEmail ?? "",
            paymentMethod: curr.paymentMethod
              ? translatePaymentMethodToCzech(curr.paymentMethod)
              : "",
            note: curr.note ?? "",
          },
        ],
        [] as Array<Record<keyof ReportColumns, string>>
      );

    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [Object.values(header)]);
    XLSX.utils.sheet_add_json(ws, participantRows, {
      origin: "A2",
      skipHeader: true,
    });
    // @ts-ignore
    ws["!cols"] = [
      { wch: 20 },
      { wch: 25 },
      { wch: 16 },
      { wch: 16 },
      { wch: 30 },
      { wch: 20 },
      { wch: 25 },
      { wch: 15 },
      { wch: 35 },
    ];

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    XLSX.writeFile(wb, "seminar.xlsx");
  };

  return {
    generateXlsx,
  };
};
