<template>
  <div class="column fullscreen items-center justify-center q-gutter-sm">
    <template v-if="error">
      <Heading1>Nepodařilo se přihlásit</Heading1>
      <div class="text-subtitle1 q-my-md">{{ error }}</div>
      <div
        v-if="error === 'Access denied'"
        class="q-mx-lg text-justify"
        style="max-width: 500px"
      >
        <p>
          Přihlášení je možné pouze přes Google účet pod doménou
          <i>@vys-edu.cz</i>. Pokud používáte na tomto zařízení jiný Google účet
          (např. pod emailem <i>jan.novak@gmail.com</i>) a je to zároveň jediný
          účet, pod kterým jste přihlášeni, prohlížeč se automaticky pokusí
          přihlásit pod tímto jiným účtem. Pokud je toto váš případ, jděte na
          <a href="https://www.google.com/">Google</a> a nejprve se přihlašte
          pod vaším <i>@vys-edu.cz</i> účtem. Tím prohlížeč pozná, že má k
          dispozici 2 účty a v procesu přihlašování vám nabídne, pod kterým
          účtem se chcete přihlásit.
        </p>
        <p>
          Pokud jste zkusili i toto a nedaří se Vám přihlásit, kontaktujte
          administrátora webu:
          <a
            class="text-black"
            href="mailto:dominik@dominikjasek.cz"
            style="text-decoration: none"
            >Dominik Jašek</a
          >.
        </p>
      </div>
    </template>

    <LoginButton />
    <q-btn outline @click="showLoginHelpDialog = true">Jak se přihlásit?</q-btn>

    <q-dialog v-model="showLoginHelpDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Přihlášení pro metodiky Vysočina Education</div>
        </q-card-section>

        <q-stepper v-model="step" animated color="primary" vertical>
          <q-step
            :done="step > 1"
            :name="1"
            icon="settings"
            title="Možnosti přihlášení"
          >
            Z bezpečnostních důvodů je možné se přihlásit pouze pomocí Google
            účtu. Pokud se vám nedaří přihlásit se, kontaktujte administrátora -
            <a href="mailto:dominik@dominikjasek.cz">Dominik Jašek</a>

            <q-stepper-navigation>
              <q-btn color="primary" label="Rozumím" @click="step = 2" />
            </q-stepper-navigation>
          </q-step>

          <q-step
            :done="step > 2"
            :name="2"
            icon="assignment"
            title="Vytvoření účtu"
          >
            <p>
              Pro přihlášení musíte mít vytvořený Google účet pod doménou
              <span class="text-italic">@vys-edu.cz</span>. Pokud už jste si
              účet vytvořili, můžete tento návod zavřít a rovnou se přihlásit.
            </p>
            <p>
              Pokud účet nemáte, můžete si jej založit
              <a href="https://accounts.google.com/signup" target="_blank"
                >zde</a
              >.
            </p>
            <p>
              Zvolte
              <span
                class="q-ma-xs q-pa-xs label bg-primary text-white text-uppercase"
              >
                Použít aktuální emailovou adresu </span
              >, do které napište svůj pracovní email (např.
              <span class="text-italic">jaskova@vys-edu.cz</span>)
            </p>
            <p>
              Nápovědu k vytvoření google účtu naleznete
              <a
                href="https://support.google.com/accounts/answer/27441?hl=cs"
                target="_blank"
                >zde</a
              >.
            </p>

            <q-stepper-navigation>
              <q-btn color="primary" label="Pokračovat" @click="step = 3" />
              <q-btn
                class="q-ml-sm"
                color="primary"
                flat
                label="Zpět"
                @click="step = 2"
              />
            </q-stepper-navigation>
          </q-step>

          <q-step
            :done="step > 2"
            :name="3"
            icon="mark_email_read"
            title="Potvrzení emailu"
          >
            <p>
              Pro dokončení vytvoření budete muset povrdit váš email, postupujte
              podle návodu a zkontrolujte, že vám přišel potvrzovací kód.
            </p>
            <q-stepper-navigation>
              <q-btn color="primary" label="Pokračovat" @click="step = 4" />
              <q-btn
                class="q-ml-sm"
                color="primary"
                flat
                label="Zpět"
                @click="step = 2"
              />
            </q-stepper-navigation>
          </q-step>

          <q-step :name="4" icon="sports_score" title="Dokončeno">
            <p>
              Nyní byste měli mít správně vytvořený účet a můžete se pomocí něj
              přihlásit.
            </p>

            <q-stepper-navigation>
              <q-btn color="primary" label="Dokončit" @click="closeDialog" />
              <q-btn
                class="q-ml-sm"
                color="primary"
                flat
                label="Zpět"
                @click="step = 3"
              />
            </q-stepper-navigation>
          </q-step>
        </q-stepper>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" setup>
import LoginButton from "@/components/auth/LoginButton.vue";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import Heading1 from "@/components/common/heading1.vue";

const route = useRoute();
const error = computed(() => route.query.err);

const showLoginHelpDialog = ref(false);
const step = ref(1);

const closeDialog = () => {
  showLoginHelpDialog.value = false;
  step.value = 1;
};
</script>

<style lang="scss"></style>
