import { z } from "zod";
import { toFormValidator } from "@vee-validate/zod";

const zodSchema = z.object({
  participants: z.array(
    z.object({
      name: z.string().min(1, "Zadejte jméno účastníka"),
      dateOfBirth: z
        .string()
        .regex(
          /^\d{1,2}\.\d{1,2}\.\d{4}$/,
          "Zadejte datum narození ve formátu 01.01.1967"
        ),
    })
  ),
});

export const addParticipantsValidationSchema = toFormValidator(zodSchema);
export type AddParticipantsSchema = z.infer<typeof zodSchema>;
