import { graphql } from "@/api/graphql/codegen";

export const UpdateSeminarMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateSeminar($seminarInput: UpdateSeminarInput!) {
    updateSeminar(updateSeminarInput: $seminarInput) {
      id
      lector
      name
      date
      accreditation
    }
  }
`);
