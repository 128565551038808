<template>
  <q-header class="bg-primary text-white" elevated>
    <q-toolbar>
      <Link to="/">
        <q-btn class="gt-xs" flat>Vysočina education - semináře</q-btn>
      </Link>
      <q-space />

      <q-tabs v-model="tab" class="q-mr-lg" shrink>
        <template v-for="tab in tabs" :key="tab.label">
          <Link :to="tab.link">
            <q-tab :label="tab.label" :name="tab.link"></q-tab>
          </Link>
        </template>
      </q-tabs>
      <template v-if="isAuthenticated">
        <q-btn-dropdown :label="user.name" class="gt-xs md-show" flat>
          <Link to="/profile">
            <q-item v-close-popup clickable>
              <q-item-section>
                <q-item-label>Profil</q-item-label>
              </q-item-section>
            </q-item>
          </Link>
        </q-btn-dropdown>
      </template>
      <LogoutButton v-if="isAuthenticated" class="q-ml-md" />
    </q-toolbar>
  </q-header>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import LogoutButton from "@/components/auth/LogoutButton.vue";
import { useAuthenticatedUser } from "@/stores/auth";
import Link from "@/components/common/Link.vue";

const { isAuthenticated } = useAuthenticatedUser();
const { user } = useAuthenticatedUser();

const route = useRoute();

const tabs = ref([
  {
    label: "Semináře",
    link: "/seminars",
  },
]);

const tab = ref("");
watch(
  () => route.path,
  () => {
    const newPath = route.path;
    if (tabs.value.some((tab) => tab.link === newPath)) {
      tab.value = newPath;
    }
  }
);
</script>
