<template>
  <div v-if="seminar && participants">
    <Heading1>
      {{ seminar.name }}
    </Heading1>
    <div class="row items-center q-my-md">
      <q-chip :ripple="false" icon="today">
        {{ formatDateWithTime(seminar.date) }}
        {{ seminar.endDate ? ` - ${formatDateWithTime(seminar.endDate)}` : "" }}
      </q-chip>
      <q-chip :ripple="false" icon="school">
        {{ seminar.lector }}
      </q-chip>
      <q-chip :ripple="false" icon="location_city">
        {{ seminar.city }}
      </q-chip>
      <q-chip v-if="seminar.duration" :ripple="false" icon="timelapse">
        {{ seminar.duration }}
      </q-chip>
      <q-chip v-if="seminar.price" :ripple="false" icon="payment">
        {{ seminar.price }} Kč
      </q-chip>
      <q-chip :ripple="false" icon="people">
        <template v-if="seminar.participants?.length === 0">
          žádný účastník
        </template>
        <template v-else-if="seminar.participants?.length === 1">
          {{ seminar.participants.length }} účastník
        </template>
        <template
          v-else-if="
            seminar.participants?.length &&
            seminar.participants.length > 2 &&
            seminar.participants.length < 5
          "
        >
          {{ seminar.participants.length }} účastníci
        </template>
        <template v-else>
          {{ seminar.participants?.length }} účastníků
        </template>
      </q-chip>
    </div>
    <div class="row items-center q-my-md q-gutter-sm">
      <q-btn
        class="q-mx-sm"
        color="primary"
        @click="$router.push(`/seminar/edit/${seminarId}`)"
      >
        Upravit seminář
      </q-btn>
      <q-btn
        class="q-mx-sm"
        color="secondary"
        @click="isAddParticipantsDialogOpen = true"
      >
        <q-icon :name="matGroupAdd" class="q-mr-xs" size="2.2em" />
        Přidat účastníky
      </q-btn>
      <q-btn
        v-if="registrationLink"
        class="q-mx-sm"
        color="secondary"
        @click="copyRegistrationUrl"
      >
        <q-icon class="q-mr-xs" name="content_copy" size="2.2em" />
        Kopírovat odkaz přihlášky
      </q-btn>
      <q-btn
        v-if="seminar.participants?.length"
        class="q-mx-sm"
        color="secondary"
        outline
        @click="copyParticipantsEmails"
      >
        <q-icon class="q-mr-xs" name="content_copy" size="2.2em" />
        Kopírovat emaily
      </q-btn>
      <q-btn
        :disable="participants.length === 0"
        class="q-mx-sm"
        color="secondary"
        outline
        @click="isAttendanceListDialogOpen = true"
      >
        <q-icon name="download" size="2.2em" />
        Prezenční listina
      </q-btn>
      <q-btn
        :disable="participants.length === 0"
        class="q-mx-sm"
        color="secondary"
        outline
        @click="seminar && generateCertificates(seminar)"
      >
        <q-icon name="download" size="2.2em" />
        Osvědčení
      </q-btn>
      <q-btn
        :disable="participants.length === 0"
        class="q-mx-sm"
        color="secondary"
        outline
        @click="seminar && generateXlsx(seminar)"
      >
        <q-icon name="download" size="2.2em" />
        Excel
      </q-btn>
    </div>

    <q-separator class="q-my-md" />

    <div class="text-h5">
      <ParticipantsTable
        :is-deleting-participants="isDeletingParticipants"
        :participants="participants"
        @update:participant="updateParticipant($event)"
        @delete-participants="deleteParticipants($event)"
      />
    </div>

    <GenerateAttendanceListDialog
      v-if="seminar"
      v-model:open="isAttendanceListDialogOpen"
      :seminar="seminar"
      @close="isAttendanceListDialogOpen = false"
    />

    <AddParticipantsDialog
      v-if="seminar && isAddParticipantsDialogOpen"
      v-model:open="isAddParticipantsDialogOpen"
      :isSubmitting="isAddParticipantsSubmitting"
      :seminar="seminar"
      @submit="submitAddParticipants($event)"
    />
  </div>
  <TableSkeleton v-else-if="isLoading" />
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import Heading1 from "@/components/common/heading1.vue";
import ParticipantsTable from "@/components/seminar/Participant/ParticipantsTable.vue";
import type { Participant } from "@/components/seminar/Participant/types";
import { useGenerateCertificates } from "@/views/seminars/Documents/generate-certificates";
import type { Seminar } from "@/components/seminar/SeminarTable/types";
import { copyToClipboard, useQuasar } from "quasar";
import TableSkeleton from "@/components/skeleton/TableSkeleton.vue";
import GenerateAttendanceListDialog from "@/views/seminars/Documents/GenerateAttendanceListDialog.vue";
import { useGenerateParticipantsXlsx } from "@/views/seminars/Documents/generate-participants-xlsx";
import { GetSeminarQueryDocument } from "@/api/graphql/documents/GetSeminar";
import { useGraphQLQuery } from "@/api/graphql/adapters/use-query";
import { useGraphQLMutation } from "@/api/graphql/adapters";
import { UpdateParticipantMutationDocument } from "@/api/graphql/documents/UpdateParticipant";
import { DeleteParticipantsMutationDocument } from "@/api/graphql/documents/DeleteParticipants";
import type { GetSeminarQueryVariables } from "@/api/graphql/codegen/graphql";
import { useQueryClient } from "@tanstack/vue-query";
import AddParticipantsDialog from "@/views/seminars/Documents/AddParticipantsDialog.vue";
import { AddParticipantsMutationDocument } from "@/api/graphql/documents/AddParticipants";
import type { PartialParticipant } from "@/views/seminars/Documents/types";
import { formatDateWithTime } from "date-formatter";
import { matGroupAdd } from "@quasar/extras/material-icons";
import { useAuth0 } from "@auth0/auth0-vue";

const $q = useQuasar();
const queryClient = useQueryClient();
const { user } = useAuth0();

const isAttendanceListDialogOpen = ref(false);
const isAddParticipantsDialogOpen = ref(false);

const { mutate: addParticipantsInput, isLoading: isAddParticipantsSubmitting } =
  useGraphQLMutation(AddParticipantsMutationDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

const submitAddParticipants = (participants: PartialParticipant[]) => {
  addParticipantsInput(
    {
      addParticipantsInput: {
        participants: participants.map((p) => {
          const dateParts = p.dateOfBirth
            .trim()
            .replace(" ", "")
            .replace("-", ".")
            .split(".");
          // Extract day, month, and year from the parts
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1; // JavaScript months are 0-based (0 = January, 11 = December)
          const year = Number(dateParts[2]);

          const date = new Date(year, month, day);

          return {
            name: p.name,
            dateOfBirth: date.toISOString(),
            seminarId: seminarId.value,
          };
        }),
      },
    },
    {
      onSettled() {
        isAddParticipantsDialogOpen.value = false;
      },
    }
  );
};

const { mutate: mutateUpdateParticipant } = useGraphQLMutation(
  UpdateParticipantMutationDocument,
  {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  }
);

const { mutate: deleteParticipantsMutate, isLoading: isDeletingParticipants } =
  useGraphQLMutation(DeleteParticipantsMutationDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
const { generateCertificates } = useGenerateCertificates();
const { generateXlsx } = useGenerateParticipantsXlsx();

const route = useRoute();
const seminarId = computed(() => Number(route.params.id));

const seminar = ref<Seminar | null>(null);

const getSeminarQueryVariables = computed<GetSeminarQueryVariables>(() => ({
  seminarInput: { seminarId: seminarId.value },
}));
const { isLoading, queryKey } = useGraphQLQuery(
  GetSeminarQueryDocument,
  getSeminarQueryVariables,
  {
    onSuccess: (res) => {
      seminar.value = res?.seminars[0] ?? null;
    },
  }
);

const participants = computed<Participant[] | undefined>({
  get() {
    return seminar.value?.participants ?? undefined;
  },
  set(participants) {
    if (!seminar.value) {
      return;
    }

    seminar.value = {
      ...seminar.value,
      participants: participants ?? [],
    };
  },
});

const updateParticipant = async (newParticipantValue: Participant) => {
  await mutateUpdateParticipant({
    participantInput: {
      id: newParticipantValue.id,
      email: newParticipantValue.email,
      name: newParticipantValue.name,
      paymentMethod: newParticipantValue.paymentMethod,
      phone: newParticipantValue.phone,
      school: newParticipantValue.school,
      ico: newParticipantValue.ico,
      invoiceEmail: newParticipantValue.invoiceEmail,
      dateOfBirth: newParticipantValue.dateOfBirth,
    },
  });
};

const deleteParticipants = async (selectedParticipantIds: string[]) => {
  $q.dialog({
    title: "Potvrzení smazání účastníků",
    message: `Opravdu chcete tyto účastníky odstranit? Tato akce nelze vzít zpátky?`,
    color: "negative",
    ok: `Ano`,
    cancel: true,
  }).onOk(async () => {
    await deleteParticipantsMutate({
      participants: { participantIds: selectedParticipantIds },
    });
  });
};

const copyParticipantsEmails = () => {
  if (!seminar.value) return;

  copyToClipboard(
    seminar.value.participants?.map((p) => p.email).join(";") + " "
  ).then(() => {
    $q.notify({
      message: "Emaily účastníků byly zkopírovány do schránky",
      color: "positive",
    });
  });
};

const copyRegistrationUrl = () => {
  if (!seminar.value) return;

  copyToClipboard(registrationLink.value ?? "").then(() => {
    $q.notify({
      message: "Odkaz na registraci byl zkopírován do schránky",
      color: "positive",
    });
  });
};

const registrationLink = computed(() =>
  seminar.value
    ? `${import.meta.env.VITE_CLIENT_APP_URL}/seminar/register/${
        seminar.value.id
      }`
    : null
);

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
