// index.ts
var convertToDateType = (date) => {
  return typeof date === "object" ? date : new Date(date);
};
var formatDate = (date) => {
  return convertToDateType(date).toLocaleDateString("cs-CZ", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "Europe/Prague"
  });
};
var formatDateWithTime = (date) => {
  return convertToDateType(date).toLocaleDateString("cs-CZ", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    timeZone: "Europe/Prague"
  });
};
export {
  formatDate,
  formatDateWithTime
};
