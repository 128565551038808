import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import type { QuasarPluginOptions } from "quasar";
import { Dialog, Loading, Notify } from "quasar";
import langCs from "quasar/lang/cs";

// there is some typescript error, I need to omit these
export const quasarOptions: Omit<
  QuasarPluginOptions,
  "iconSet" | "components" | "directives"
> = {
  plugins: {
    Loading,
    Notify,
    Dialog,
  },
  lang: langCs,
  config: {
    loading: {},
    notify: {},
    dialog: {},
  },
};
