<template>
  <Loading v-if="loadingIdentity" />
  <template v-else-if="identity">
    <Heading1>Uživatelský profil</Heading1>
    <ProfileForm
      :identity="identity.identity"
      :is-submitting="loadingUpdateIdentity"
      @submit="onSubmit"
    />
  </template>
</template>

<script lang="ts" setup>
import Heading1 from "@/components/common/heading1.vue";
import Loading from "@/components/loading/Loading.vue";
import ProfileForm from "@/components/profile/ProfileForm/ProfileForm.vue";
import type { ProfileFormValues } from "@/components/profile/ProfileForm/profile.form";
import { useQuasar } from "quasar";
import { useGraphQLMutation, useGraphQLQuery } from "@/api/graphql/adapters";
import { GetUserIdentityQueryDocument } from "@/api/graphql/documents/GetUserIdentity";
import { UpdateUserIdentityMutationDocument } from "@/api/graphql/documents/UpdateUserIdentity";

const $q = useQuasar();

const { data: identity, isLoading: loadingIdentity } = useGraphQLQuery(
  GetUserIdentityQueryDocument
);
const { mutate, isLoading: loadingUpdateIdentity } = useGraphQLMutation(
  UpdateUserIdentityMutationDocument
);

const onSubmit = async (data: ProfileFormValues) => {
  await mutate({ userIdentity: data });
  $q.notify({
    color: "positive",
    message: "Vaše údaje byly úspěšně aktualizovány.",
    progress: true,
  });
};
</script>

<style lang="scss"></style>
