<template>
  <Form
    :initial-values="initialValues"
    :validation-schema="profileValidationSchema"
    @submit="onSubmit"
  >
    <div class="column" style="max-width: 300px">
      <Field v-slot="{ errorMessage, value, field }" name="firstName">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Jméno"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="lastName">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Příjmení"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="degree">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Titul"
          placeholder="např. Mgr."
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="email">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="E-mail"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="phone">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Telefon"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="address">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Adresa pracoviště"
          placeholder="např. pracoviště Třebíč, Družstevní 1320, 674 01"
          v-bind="field"
        />
      </Field>
    </div>

    <q-btn
      :loading="isSubmitting"
      class="q-mt-md"
      color="secondary"
      type="submit"
    >
      Uložit
    </q-btn>
  </Form>
</template>

<script lang="ts" setup>
import type { ProfileFormValues } from "@/components/profile/ProfileForm/profile.form";
import { profileValidationSchema } from "@/components/profile/ProfileForm/profile.form";
import { Field, Form } from "vee-validate";
import { ref } from "vue";

const props = defineProps<{
  identity: ProfileFormValues;
  isSubmitting?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit", values: ProfileFormValues): void;
}>();

const initialValues = ref<ProfileFormValues>({
  firstName: props.identity.firstName ?? "",
  lastName: props.identity.lastName ?? "",
  email: props.identity.email ?? "",
  phone: props.identity.phone ?? "",
  address: props.identity.address ?? "",
  degree: props.identity.degree ?? "",
});

const onSubmit = (values: any) => {
  emit("submit", values as ProfileFormValues);
};
</script>

<style lang="scss"></style>
