import { graphql } from "@/api/graphql/codegen";

export const CreateSeminarMutationDocument = graphql(/* GraphQL */ `
  mutation CreateSeminar($seminarInput: CreateSeminarInput!) {
    createSeminar(createSeminarInput: $seminarInput) {
      id
      identifier
      lector
      date
      name
      accreditation
    }
  }
`);
