<template>
  <q-dialog
    :model-value="localOpen"
    @update:modelValue="$emit('update:open', $event)"
  >
    <q-card class="q-pa-sm" style="width: 500px; max-width: 80%">
      <q-card-section>
        <div class="text-h5">Prezenční listina</div>
      </q-card-section>
      <q-card-section>
        <q-banner
          v-if="!allUserIdentityDataAreFilled"
          class="bg-orange text-white q-mb-lg"
          inline-actions
          rounded
        >
          Máte vyplněné všechny vaše údaje? Prezenční listina může být neúplná.

          <template v-slot:action>
            <Link to="/profile">
              <q-btn flat label="Přejít na profil" />
            </Link>
          </template>
        </q-banner>

        <div class="q-gutter-sm">
          <div class="text-h6">Orientace</div>
          <q-radio v-model="orientation" label="Na výšku" val="vertical" />
          <q-radio v-model="orientation" label="Na šířku" val="horizontal" />
        </div>

        <div class="q-mt-md q-gutter-sm">
          <div class="text-h6">Počet sloupců na podpisy</div>
          <q-input v-model.number="numOfColumns" type="number" />
        </div>
      </q-card-section>
      <q-card-actions class="row justify-end" @click="generate">
        <q-btn color="secondary"> Stáhnout</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useGenerateAttendanceList } from "@/views/seminars/Documents/generate-attendance-list";
import type { ParticularSeminar } from "@/components/seminar/SeminarTable/types";
// import { useGetUserIdentityQuery } from "@/api/graphql/generated-types";
import Link from "@/components/common/Link.vue";

const { generateAttendanceList } = useGenerateAttendanceList();

const props = defineProps<{
  seminar: ParticularSeminar;
  open: boolean;
}>();
const localOpen = ref(false);
watch(
  () => props.open,
  (val) => {
    localOpen.value = val;
  },
  { immediate: true }
);

const emit = defineEmits<{
  (e: "close", value: void): void;
}>();

const allUserIdentityDataAreFilled = computed(
  () =>
    props.seminar.author &&
    Object.values(props.seminar.author).every((val) => Boolean(val))
);

const orientation = ref<"horizontal" | "vertical">("vertical");
const numOfColumns = ref(1);

const generate = () => {
  generateAttendanceList(props.seminar, {
    verticalLayout: orientation.value === "vertical",
    numOfSignColumns: numOfColumns.value,
  });
  emit("close");
};
</script>

<style lang="scss"></style>
