/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation AddParticipantsMutation(\n    $addParticipantsInput: AddParticipantsInput!\n  ) {\n    addParticipants(addParticipantsInput: $addParticipantsInput) {\n      participants {\n        name\n      }\n    }\n  }\n": types.AddParticipantsMutationDocument,
    "\n  mutation CreateSeminar($seminarInput: CreateSeminarInput!) {\n    createSeminar(createSeminarInput: $seminarInput) {\n      id\n      identifier\n      lector\n      date\n      name\n      accreditation\n    }\n  }\n": types.CreateSeminarDocument,
    "\n  mutation DeleteParticipants($participants: DeleteParticipantsInput!) {\n    deleteParticipants(deleteParticipantsInput: $participants) {\n      deletedParticipants {\n        id\n      }\n    }\n  }\n": types.DeleteParticipantsDocument,
    "\n  mutation DeleteSeminar($seminar: DeleteSeminarInput!) {\n    deleteSeminar(deleteSeminarInput: $seminar) {\n      id\n    }\n  }\n": types.DeleteSeminarDocument,
    "\n  query GetSeminar($seminarInput: GetSeminarsInput!) {\n    seminars(seminarsInput: $seminarInput) {\n      id\n      identifier\n      name\n      date\n      endDate\n      lector\n      accreditation\n      city\n      duration\n      price\n      participants {\n        id\n        name\n        dateOfBirth\n        paymentMethod\n        email\n        phone\n        school\n        ico\n        invoiceEmail\n        note\n        createdAt\n        updatedAt\n      }\n      author {\n        firstName\n        lastName\n        degree\n        email\n        address\n        phone\n      }\n    }\n  }\n": types.GetSeminarDocument,
    "\n  query GetSeminars($seminarsInput: GetSeminarsInput!) {\n    seminars(seminarsInput: $seminarsInput) {\n      id\n      identifier\n      name\n      date\n      endDate\n      lector\n      accreditation\n      city\n      duration\n      price\n      participants {\n        id\n        name\n        dateOfBirth\n        paymentMethod\n        email\n        phone\n        school\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.GetSeminarsDocument,
    "\n  query GetUserIdentity {\n    identity {\n      email\n      address\n      firstName\n      lastName\n      degree\n      phone\n    }\n  }\n": types.GetUserIdentityDocument,
    "\n  mutation UpdateParticipant($participantInput: UpdateParticipantInput!) {\n    updateParticipant(updateParticipantInput: $participantInput) {\n      id\n    }\n  }\n": types.UpdateParticipantDocument,
    "\n  mutation UpdateSeminar($seminarInput: UpdateSeminarInput!) {\n    updateSeminar(updateSeminarInput: $seminarInput) {\n      id\n      lector\n      name\n      date\n      accreditation\n    }\n  }\n": types.UpdateSeminarDocument,
    "\n  mutation UpdateUserIdentity($userIdentity: UpdateIdentityInput!) {\n    updateUserIdentity(identity: $userIdentity) {\n      firstName\n      lastName\n      degree\n      phone\n      address\n      email\n    }\n  }\n": types.UpdateUserIdentityDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddParticipantsMutation(\n    $addParticipantsInput: AddParticipantsInput!\n  ) {\n    addParticipants(addParticipantsInput: $addParticipantsInput) {\n      participants {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddParticipantsMutation(\n    $addParticipantsInput: AddParticipantsInput!\n  ) {\n    addParticipants(addParticipantsInput: $addParticipantsInput) {\n      participants {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSeminar($seminarInput: CreateSeminarInput!) {\n    createSeminar(createSeminarInput: $seminarInput) {\n      id\n      identifier\n      lector\n      date\n      name\n      accreditation\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSeminar($seminarInput: CreateSeminarInput!) {\n    createSeminar(createSeminarInput: $seminarInput) {\n      id\n      identifier\n      lector\n      date\n      name\n      accreditation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteParticipants($participants: DeleteParticipantsInput!) {\n    deleteParticipants(deleteParticipantsInput: $participants) {\n      deletedParticipants {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteParticipants($participants: DeleteParticipantsInput!) {\n    deleteParticipants(deleteParticipantsInput: $participants) {\n      deletedParticipants {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSeminar($seminar: DeleteSeminarInput!) {\n    deleteSeminar(deleteSeminarInput: $seminar) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSeminar($seminar: DeleteSeminarInput!) {\n    deleteSeminar(deleteSeminarInput: $seminar) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSeminar($seminarInput: GetSeminarsInput!) {\n    seminars(seminarsInput: $seminarInput) {\n      id\n      identifier\n      name\n      date\n      endDate\n      lector\n      accreditation\n      city\n      duration\n      price\n      participants {\n        id\n        name\n        dateOfBirth\n        paymentMethod\n        email\n        phone\n        school\n        ico\n        invoiceEmail\n        note\n        createdAt\n        updatedAt\n      }\n      author {\n        firstName\n        lastName\n        degree\n        email\n        address\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSeminar($seminarInput: GetSeminarsInput!) {\n    seminars(seminarsInput: $seminarInput) {\n      id\n      identifier\n      name\n      date\n      endDate\n      lector\n      accreditation\n      city\n      duration\n      price\n      participants {\n        id\n        name\n        dateOfBirth\n        paymentMethod\n        email\n        phone\n        school\n        ico\n        invoiceEmail\n        note\n        createdAt\n        updatedAt\n      }\n      author {\n        firstName\n        lastName\n        degree\n        email\n        address\n        phone\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSeminars($seminarsInput: GetSeminarsInput!) {\n    seminars(seminarsInput: $seminarsInput) {\n      id\n      identifier\n      name\n      date\n      endDate\n      lector\n      accreditation\n      city\n      duration\n      price\n      participants {\n        id\n        name\n        dateOfBirth\n        paymentMethod\n        email\n        phone\n        school\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSeminars($seminarsInput: GetSeminarsInput!) {\n    seminars(seminarsInput: $seminarsInput) {\n      id\n      identifier\n      name\n      date\n      endDate\n      lector\n      accreditation\n      city\n      duration\n      price\n      participants {\n        id\n        name\n        dateOfBirth\n        paymentMethod\n        email\n        phone\n        school\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUserIdentity {\n    identity {\n      email\n      address\n      firstName\n      lastName\n      degree\n      phone\n    }\n  }\n"): (typeof documents)["\n  query GetUserIdentity {\n    identity {\n      email\n      address\n      firstName\n      lastName\n      degree\n      phone\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateParticipant($participantInput: UpdateParticipantInput!) {\n    updateParticipant(updateParticipantInput: $participantInput) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateParticipant($participantInput: UpdateParticipantInput!) {\n    updateParticipant(updateParticipantInput: $participantInput) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSeminar($seminarInput: UpdateSeminarInput!) {\n    updateSeminar(updateSeminarInput: $seminarInput) {\n      id\n      lector\n      name\n      date\n      accreditation\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSeminar($seminarInput: UpdateSeminarInput!) {\n    updateSeminar(updateSeminarInput: $seminarInput) {\n      id\n      lector\n      name\n      date\n      accreditation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserIdentity($userIdentity: UpdateIdentityInput!) {\n    updateUserIdentity(identity: $userIdentity) {\n      firstName\n      lastName\n      degree\n      phone\n      address\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserIdentity($userIdentity: UpdateIdentityInput!) {\n    updateUserIdentity(identity: $userIdentity) {\n      firstName\n      lastName\n      degree\n      phone\n      address\n      email\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;