<template>
  <q-btn
    color="primary"
    icon="login"
    label="Přihlásit se"
    text-color="white"
    @click="login"
  />
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";

const auth0 = useAuth0();

const login = () => {
  auth0.loginWithRedirect();
};
</script>
