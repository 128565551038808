import { graphql } from "@/api/graphql/codegen";

export const GetUserIdentityQueryDocument = graphql(/* GraphQL */ `
  query GetUserIdentity {
    identity {
      email
      address
      firstName
      lastName
      degree
      phone
    }
  }
`);
