<template>
  <q-table
    v-model:selected="selected"
    :columns="[
      {
        name: 'name',
        required: true,
        label: 'Jméno',
        align: 'left',
        field: (row: Participant) => row.name,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'dateOfBirth',
        required: true,
        label: 'Datum narození',
        align: 'left',
        field: (row: Participant) => row.dateOfBirth,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'paymentMethod',
        required: true,
        label: 'Typ platby',
        align: 'left',
        field: (row: Participant) => row.paymentMethod,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'email',
        required: true,
        label: 'Email účastníka',
        align: 'left',
        field: (row: Participant) => row.email,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'phone',
        required: true,
        label: 'Telefon',
        align: 'left',
        field: (row: Participant) => row.phone,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'school',
        required: true,
        label: 'Název školy',
        align: 'left',
        style: 'width: 500px',
        field: (row: Participant) => row.school,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'ico',
        required: true,
        label: 'IČO',
        align: 'left',
        style: 'width: 500px',
        field: (row: Participant) => row.ico,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'invoiceEmail',
        required: true,
        label: 'Fakturační email',
        align: 'left',
        style: 'width: 500px',
        field: (row: Participant) => row.invoiceEmail,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'note',
        required: true,
        label: 'Poznámka',
        align: 'left',
        style: 'width: 100px; max-width: 300px; overflow: hidden',
        field: (row: Participant) => row.note,
        format: (val) => `${val}`,
        sortable: false,
      },
      {
        name: 'createdAt',
        required: true,
        label: 'Datum registrace',
        align: 'left',
        field: (row: Participant) => row.createdAt,
        format: (val) => `${val}`,
        sortable: true,
      },
    ]"
    :pagination="{
      rowsPerPage: 30,
      sortBy: 'createdAt',
      descending: true,
    }"
    :rows="rows"
    binary-state-sort
    no-data-label="Zatím nejsou zaregistrovaní žádní účastníci"
    row-key="id"
    selection="multiple"
  >
    <template v-slot:top>
      <span class="text-h6">Registrovaní účastníci</span>

      <q-space />

      <q-btn
        :disable="selected.length === 0"
        :loading="isDeletingParticipants"
        color="red"
        round
        @click="
          $emit(
            'delete-participants',
            selected.map((participant: Participant) =>
              participant.id.toString()
            )
          )
        "
      >
        <q-icon name="delete" />
      </q-btn>
    </template>

    <template v-slot:header-selection="scope">
      <q-checkbox v-model="scope.selected" />
    </template>

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td>
          <q-checkbox v-model="props.selected" color="primary" />
        </q-td>

        <q-td key="name" :props="props">
          {{ props.row.name }}
          <q-popup-edit
            v-slot="scope"
            v-model="props.row.name"
            buttons
            @save="updateRow(props.row, 'name', $event)"
          >
            <q-input
              v-model="scope.value"
              autofocus
              counter
              dense
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="dateOfBirth" :props="props">
          {{ convertToQuasarDate(new Date(props.row.dateOfBirth), false) }}
          <q-popup-edit
            v-slot="scope"
            :model-value="
              convertToQuasarDate(new Date(props.row.dateOfBirth), false)
            "
            buttons
            @save="
              updateRow(props.row, 'dateOfBirth', convertFromQuasarDate($event))
            "
          >
            <q-input
              v-model="scope.value"
              :mask="dateOnly_InputMask"
              autofocus
              counter
              dense
              hint="Ve formátu 01. 01. 2023"
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="paymentMethod" :props="props">
          {{ translatePaymentMethodToCzech(props.row.paymentMethod) }}
          <q-popup-edit
            v-slot="scope"
            :model-value="
              translatePaymentMethodToCzech(props.row.paymentMethod)
            "
            buttons
            @save="
              updateRow(
                props.row,
                'paymentMethod',
                translatePaymentMethodToEnum($event)
              )
            "
          >
            <q-select
              v-model="scope.value"
              :options="translatedPaymentMethods"
              autofocus
              label="Platba"
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="email" :props="props">
          {{ props.row.email }}
          <q-popup-edit
            v-slot="scope"
            v-model="props.row.email"
            buttons
            @save="updateRow(props.row, 'email', $event)"
          >
            <q-input
              v-model="scope.value"
              autofocus
              counter
              dense
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="phone" :props="props">
          {{ props.row.phone }}
          <q-popup-edit
            v-slot="scope"
            v-model="props.row.phone"
            buttons
            @save="updateRow(props.row, 'phone', $event)"
          >
            <q-input
              v-model="scope.value"
              autofocus
              counter
              dense
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="school" :props="props">
          {{ props.row.school }}
          <q-popup-edit
            v-slot="scope"
            v-model="props.row.school"
            buttons
            @save="updateRow(props.row, 'school', $event)"
          >
            <q-input
              v-model="scope.value"
              autofocus
              counter
              dense
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="ico" :props="props">
          {{ props.row.ico }}
          <q-popup-edit
            v-slot="scope"
            v-model="props.row.ico"
            buttons
            @save="updateRow(props.row, 'ico', $event)"
          >
            <q-input
              v-model="scope.value"
              autofocus
              counter
              dense
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="invoiceEmail" :props="props">
          {{ props.row.invoiceEmail }}
          <q-popup-edit
            v-slot="scope"
            v-model="props.row.invoiceEmail"
            buttons
            @save="updateRow(props.row, 'invoiceEmail', $event)"
          >
            <q-input
              v-model="scope.value"
              autofocus
              counter
              dense
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="note" :props="props">
          {{ props.row.note }}
          <q-popup-edit
            v-slot="scope"
            v-model="props.row.note"
            buttons
            @save="updateRow(props.row, 'note', $event)"
          >
            <q-input
              v-model="scope.value"
              autofocus
              counter
              dense
              @keyup.enter="scope.set"
            />
          </q-popup-edit>
        </q-td>

        <q-td key="createdAt" :props="props">
          {{ convertToQuasarDate(new Date(props.row.createdAt)) }}
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script lang="ts" setup>
import type { Participant } from "@/components/seminar/Participant/types";
import { ref, toRaw, toRefs, watch } from "vue";
import {
  convertFromQuasarDate,
  convertToQuasarDate,
  dateOnly_InputMask,
} from "@/components/seminar/translators/date";
import {
  translatedPaymentMethods,
  translatePaymentMethodToCzech,
  translatePaymentMethodToEnum,
} from "payment-method-translator";

const props = defineProps<{
  participants: Participant[];
  isDeletingParticipants: boolean;
}>();
const { participants, isDeletingParticipants } = toRefs(props);

const emit = defineEmits<{
  (e: "update:participant", participant: Participant): void;
  (e: "delete-participants", selectedParticipantIds: string[]): void;
}>();

const rows = ref<Participant[]>([]);
watch(
  participants,
  (participants) => {
    rows.value = structuredClone(toRaw(participants));
  },
  { immediate: true }
);

const updateRow = async (
  participant: Participant,
  columnName: string,
  newValue: unknown
) => {
  if (!Object.keys(participant).includes(columnName)) {
    throw new Error(`Participant doesn't contain column name: ${columnName}`);
  }
  Object.assign(participant, { [columnName]: newValue });
  emit("update:participant", participant);
};

const selected = ref<Participant[]>([]);
</script>
