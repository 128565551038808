<template>
  <q-footer class="bg-grey-3 text-black text-center">
    <a
      class="text-black"
      href="mailto:dominik@dominikjasek.cz"
      style="text-decoration: none"
      >Dominik Jašek</a
    >
    •
    {{ new Date().getFullYear() }}

    <!--    <q-toolbar>-->
    <!--      <q-toolbar-title> a </q-toolbar-title>-->
    <!--    </q-toolbar>-->
  </q-footer>
</template>
