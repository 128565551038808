import { graphql } from "@/api/graphql/codegen";

export const DeleteParticipantsMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteParticipants($participants: DeleteParticipantsInput!) {
    deleteParticipants(deleteParticipantsInput: $participants) {
      deletedParticipants {
        id
      }
    }
  }
`);
