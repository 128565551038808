import { graphql } from "@/api/graphql/codegen";

export const GetSeminarQueryDocument = graphql(/* GraphQL */ `
  query GetSeminar($seminarInput: GetSeminarsInput!) {
    seminars(seminarsInput: $seminarInput) {
      id
      identifier
      name
      date
      endDate
      lector
      accreditation
      city
      duration
      price
      participants {
        id
        name
        dateOfBirth
        paymentMethod
        email
        phone
        school
        ico
        invoiceEmail
        note
        createdAt
        updatedAt
      }
      author {
        firstName
        lastName
        degree
        email
        address
        phone
      }
    }
  }
`);
