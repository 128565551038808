import * as pdfMake from "pdfmake/build/pdfmake";
import type { TDocumentDefinitions, TFontDictionary } from "pdfmake/interfaces";

export const usePdfMaker = () => {
  const fonts: TFontDictionary = {
    Roboto: {
      normal:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
      italics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
      bolditalics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
    },
  };

  const generatePdf = (
    documentDefinition: TDocumentDefinitions,
    fileName: string
  ) => {
    pdfMake
      .createPdf(documentDefinition, undefined, fonts)
      .download(fileName + ".pdf");
  };

  return {
    generatePdf,
  };
};
