export const dateTime_InputMask = "##. ##. #### ##:##";
export const dateTime_DateMask = "DD. MM. YYYY HH:mm";
export const dateOnly_InputMask = "##. ##. ####";
import moment from "moment-timezone";

const dateFormat = "DD. MM. YYYY HH:mm";
const dateFormatWithoutTime = "DD. MM. YYYY";

export const convertFromQuasarDate = (quasarMaskDate: string): Date => {
  if (!quasarMaskDate) {
    throw new Error("date is not valid");
  }

  return moment(quasarMaskDate, dateFormat).tz("Europe/Prague").toDate();
};

export const convertToQuasarDate = (date: string | Date, showTime = true) => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  if (showTime) {
    return moment(date).tz("Europe/Prague").format(dateFormat);
  }

  return moment(date).format(dateFormatWithoutTime);
};

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as any);
};
