import { usePdfMaker } from "@/utils/pdf/pdf";
import type { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import type { Participant } from "@/components/seminar/Participant/types";
import type { ParticularSeminar } from "@/components/seminar/SeminarTable/types";
import { formatDate } from "date-formatter";

export const useGenerateCertificates = () => {
  const { generatePdf } = usePdfMaker();

  const generateCertificates = (seminar: ParticularSeminar) => {
    const participants = seminar.participants;
    if (!participants || participants.length === 0) return;

    const createCertificateForParticipant = (
      participant: Participant,
      identifier: number,
      addPageBreak = false
    ) => {
      const certificate: Content = [
        {
          alignment: "center",
          columns: [
            [
              {
                text: "VYSOČINA EDUCATION",
                style: "subheader",
              },
              "školské zařízení pro další vzdělávání pedagogických pracovníků",
              "příspěvková organizace IČO: 751 40 349",
              {
                text: "akreditovaná pod č.j.: 1122/2021-1",
                style: "subheader",
              },
              {
                text: "Ke Skalce 5907/47, 586 01 Jihlava",
                style: "subheader",
              },
            ],
            {
              image: "vysEduLogo",
              width: 100,
              height: 100,
              margin: [5, -10, 5, 0],
            },
          ],
        },
        {
          table: {
            widths: ["*"],
            body: [[" "], [" "]],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0 : 1;
            },
            vLineWidth: function () {
              return 0;
            },
          },
          marginTop: -15,
        },
        {
          text: `${seminar.identifier} - ${identifier}`,
          alignment: "left",
          marginTop: -15,
        },
        {
          text: "OSVĚDČENÍ",
          style: "title",
        },
        {
          text: participant.name,
          style: "subtitle",
        },
        {
          text: `narozen(a) ${formatDate(participant.dateOfBirth)}`,
          style: "default",
        },
        {
          text: `absolvoval(a) seminář`,
          style: "default",
        },
        {
          text: seminar.name,
          style: "subtitle",
          marginBottom: 60,
        },
        {
          text: seminar.accreditation
            ? `Vzdělávací program byl akreditován MŠMT ČR v rámci dalšího vzdělávání pedagogických pracovníků pod č. j. ${seminar.accreditation}`
            : "",
          style: {
            marginBottom: 40,
            alignment: "center",
          },
        },
        {
          stack: [
            `datum konání: ${formatDate(seminar.date)}${
              seminar.endDate ? ` - ${formatDate(seminar.endDate)}` : ""
            }`,
            `místo: ${seminar.city}`,
            `počet hodin: ${seminar.duration}`,
            seminar.lector ? `lektor: ${seminar.lector}` : "",
          ],
          alignment: "center",
          margin: [0, 50, 0, 50],
        },
        {
          text: `Způsob zakončení programu: Účastníci vyplnili evaluační dotazník a obdrželi osvědčení.`,
          alignment: "center",
        },
        {
          columns: [
            {
              stack: [
                seminar.city,
                `${formatDate(seminar.endDate ?? seminar.date)}`,
              ],
              alignment: "left",
            },
            {
              text: "razítko",
              alignment: "center",
              marginRight: 40,
            },
            {
              stack: [
                `v z. ..........................................`,
                "Mgr. Roman Křivánek",
                "Ředitel",
              ],
              alignment: "center",
            },
          ],
          alignment: "justify",
          absolutePosition: { x: 80, y: 730 },
        },
        {
          ...(addPageBreak
            ? {
                text: "",
                pageBreak: "after",
              }
            : { text: "" }),
        },
      ];

      return certificate;
    };

    const certificates = participants.map((participant, index) =>
      createCertificateForParticipant(
        participant,
        index + 1,
        index !== participants.length - 1
      )
    );

    const docDefinition: TDocumentDefinitions = {
      content: [...certificates],
      images: {
        vysEduLogo:
          "https://vys-edu.s3.eu-central-1.amazonaws.com/vys-edu-logo.jpeg",
      },
      styles: {
        default: {
          alignment: "center",
        },
        subheader: {
          fontSize: 13,
          bold: true,
        },
        title: {
          fontSize: 36,
          alignment: "center",
          bold: true,
          marginTop: 30,
          marginBottom: 30,
        },
        subtitle: {
          fontSize: 20,
          alignment: "center",
          bold: true,
          marginTop: 20,
          marginBottom: 20,
        },
      },
      info: {
        title: "Osvědčení",
        author: "Vysočina Education",
        subject: "Osvědčení",
        keywords: "Osvědčení",
      },
    };

    generatePdf(docDefinition, `Osvědčení - ${formatDate(seminar.date)}`);
  };

  return {
    generateCertificates,
  };
};
