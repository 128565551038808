<template>
  <Form
    v-slot="{ handleSubmit, setValues }"
    :initial-values="initialValues"
    :validation-schema="addParticipantsValidationSchema"
  >
    <q-dialog
      :model-value="localOpen"
      @update:modelValue="$emit('update:open', $event)"
    >
      <FieldArray v-slot="{ fields, push, remove }" name="participants">
        <q-card class="q-pa-sm" style="width: 600px; max-width: 80%">
          <q-card-section>
            <div class="text-h5">Přidat účastníky</div>
          </q-card-section>
          <q-card-section class="q-pl-lg">
            <q-banner
              class="bg-orange text-white q-mb-lg"
              inline-actions
              rounded
            >
              <div class="row items-center">
                <q-icon class="q-mr-sm" name="info" />
                <span>
                  Zkopírujte data z Excelu a vložte do prvního pole, nebo
                  použijte tlačítko.
                </span>
              </div>
            </q-banner>
            <q-btn
              class="bg-white text-black"
              @click="pasteFromClipboard(setValues)"
            >
              Vložit zkopírovaná data
            </q-btn>
            <ol class="q-pl-none">
              <li
                v-for="(participant, index) in fields"
                :key="index"
                class="row items-start justify-start q-mb-sm"
              >
                <span class="q-mt-sm" style="width: 38px">
                  {{ index + 1 }}.
                </span>
                <Field
                  v-slot="{ errorMessage, value, field }"
                  :name="`participants[${index}].name`"
                >
                  <q-input
                    :error="!!errorMessage"
                    :error-message="errorMessage"
                    :model-value="value"
                    class="q-mr-md"
                    dense
                    label="Jméno"
                    outlined
                    v-bind="field"
                    @paste="pasteFromClipboard(setValues)"
                  />
                </Field>
                <Field
                  v-slot="{ errorMessage, value, field }"
                  :name="`participants[${index}].dateOfBirth`"
                >
                  <q-input
                    :error="!!errorMessage"
                    :error-message="errorMessage"
                    :model-value="value"
                    class="q-mr-md"
                    dense
                    label="Datum narození"
                    outlined
                    v-bind="field"
                  />
                </Field>
                <q-btn
                  class="q-px-sm q-mt-xs"
                  round
                  size="sm"
                  @click="remove(index)"
                >
                  <q-icon name="delete" />
                </q-btn>
              </li>
            </ol>
            <q-btn class="q-mt-md" @click="push({ name: '', dateOfBirth: '' })">
              <q-icon class="q-mr-xs" name="add" size="2.2em" />
              Přidat další
            </q-btn>
          </q-card-section>
          <q-card-actions class="row justify-end">
            <q-btn
              :disable="isSubmitting"
              color="secondary"
              @click="handleSubmit($event, onSubmit as any)"
            >
              Potvrdit</q-btn
            >
          </q-card-actions>
        </q-card>
      </FieldArray>
    </q-dialog>
  </Form>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import type { ParticularSeminar } from "@/components/seminar/SeminarTable/types";
import type { PartialParticipant } from "@/views/seminars/Documents/types";
import {
  Field,
  FieldArray,
  Form,
  type FormActions,
  type SubmissionHandler,
} from "vee-validate";
import {
  type AddParticipantsSchema,
  addParticipantsValidationSchema,
} from "@/views/seminars/Documents/add-participants-form";

const props = defineProps<{
  seminar: ParticularSeminar;
  open: boolean;
  isSubmitting: boolean;
}>();
const localOpen = ref(false);
watch(
  () => props.open,
  (val) => {
    localOpen.value = val;
  },
  { immediate: true }
);

const initialValues = ref<AddParticipantsSchema>({
  participants: [{ name: "", dateOfBirth: "" }],
});

const emit = defineEmits<{
  (e: "close", value: void): void;
  (e: "update:open", value: boolean): void;
  (e: "submit", value: PartialParticipant[]): void;
}>();

const pasteFromClipboard = async (
  setValues: FormActions<AddParticipantsSchema>["setValues"]
) => {
  try {
    // Request clipboard permissions
    await navigator.clipboard.readText().then((text) => {
      // Parse the CSV data
      const rows = text.split("\n").filter((row) => row.trim() !== "");
      const data = rows.map((row) => {
        const [name, dateOfBirth] = row.split("\t");
        return { name: name.trim(), dateOfBirth: dateOfBirth.trim() };
      });

      setValues({
        participants: data,
      });
    });
  } catch (err) {
    console.error("Failed to read clipboard contents: ", err);
  }
};

const onSubmit: SubmissionHandler<AddParticipantsSchema> = (values) => {
  emit("submit", values.participants);
};
</script>
