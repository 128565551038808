import { computed } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useAuth0 } from "@auth0/auth0-vue";

export const useAuthStore = defineStore("auth", () => {
  const auth0 = useAuth0();
  const idToken = computed(() => auth0.idTokenClaims.value);
  const user = computed(() => auth0.user.value);
  const isLoading = computed(() => auth0.isLoading.value);
  const isAuthenticated = computed(() => auth0.isAuthenticated.value);

  return { user, idToken, isLoading, isAuthenticated };
});

export const useAuthenticatedUser = () => {
  const authStore = useAuthStore();

  return storeToRefs(authStore);
};
