<template>
  <q-btn
    color="white"
    label="Odhlásit se"
    text-color="primary"
    @click="logout"
  ></q-btn>
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";

const auth0 = useAuth0();

const logout = () => {
  auth0.logout({ returnTo: window.location.origin });
};
</script>

<style lang="scss"></style>
